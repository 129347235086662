import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link, useStaticQuery } from 'gatsby'
import './font-awesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNewspaper } from '@fortawesome/free-regular-svg-icons'
import moment from 'moment'

const NoticesAndNovelties = ({
  novelties,
  isHomeUcc = false,
  linkUrl = false,
  title,
  latest,
  showView = 'vertical'
}) => {
  const noveltiesLimit = showView === 'vertical' ? 3 : 4
  const data = useStaticQuery(graphql`
    query {
      allStrapiNoveltiesCategories {
        nodes {
          name
          strapiId
        }
      }
      allStrapiNovelties(sort: { fields: date, order: DESC }) {
        nodes {
          strapiId
          summary
          slug
          title
          id
          destacadoHome
          thumbnailName
          thumbnail {
            url
          }
          novelties_categories {
            id
          }
          date(formatString: "DD MMMM, YYYY", locale: "es")
          dateTo(formatString: "YYYY/MM/DD", locale: "es")
        }
      }
      allStrapiInstitution {
        nodes {
          ImageNoveltiesDefault {
            image {
              url
            }
          }
        }
      }
    }
  `)

  const { allStrapiNoveltiesCategories: noveltiesCategory } = data
  const { allStrapiNovelties: noveltiesAll } = data
  const { allStrapiInstitution: noveltiesDefault } = data

  // Creando Array de todas las noticias que se deben mostrar con sus respectivos campos
  let noveltyAll = novelties.map((noveltie) => {
    for(const nov in noveltiesAll.nodes) {
      const novelty = noveltiesAll.nodes[nov]
      if (isHomeUcc === true) {
        if (noveltie.novelty?.id === novelty.strapiId) {
          return noveltyConstructor(noveltie.destacado, novelty)
        }
      } else {
        if (noveltie?.id === novelty.strapiId) {
          return noveltyConstructor(noveltie.destacado, novelty)
        }
      }
    }
  })

  // Instanciando todas las noticias existentes
  const noveltiesLatest = noveltiesAll?.nodes?.map((novelty) => ({
    id: novelty.id,
    novelty: {
      id: novelty.id,
      slug: novelty.slug,
      title: novelty.title,
      thumbnailName: novelty.thumbnailName,
      thumbnail: { url: novelty.thumbnail?.url },
      date: novelty.date,
      dateTo: novelty.dateTo,
      novelties_categories: novelty.novelties_categories?.map((categories) => categories.id),
      summary: novelty.summary
    }
  }))  

  noveltyAll =
    latest === true ? noveltiesLatest.slice(0, noveltiesLimit) : noveltyAll

  return noveltyAll && noveltyAll.length ? (
    <div className="mb-5 container">
      {/* Title */}
      <div className="d-flex align-items-center justify-content-between mb-3 novelties-title">
        {
          <h2
            className={`title-secondary text-uppercase font-weight-extra-bold mb-0 ucc-text-blue`}
          >
            {!isHomeUcc 
              ? <FontAwesomeIcon icon={faNewspaper} className="mr-2" />
              : null
            }
            {title ? title : 'NOTICIAS'}
          </h2>
        }
        <Link
          to={!isHomeUcc && !linkUrl ? './noticias' : '/noticias'}
          className={`btn btn-sm btn-outline-dark  cards-section-button ${
            isHomeUcc ? 'rounded-pill px-3' : ''
          }`}
        >
          Ver todas
        </Link>
      </div>

      {/* Preview de las Novelties */}
      <div className="row">
        {noveltyAll?.map((noveltie, i) => {
          if (
            !isHomeUcc &&
            i <= 2 &&
            noveltie?.novelty.slug &&
            (noveltie.novelty.dateTo === null ||
              moment(noveltie.novelty.dateTo, 'YYYY/MM/DD').isSameOrAfter(moment()))
          ) {
            return (
              <div
                className={`mb-4 col-12  col-md-6 ${
                  showView === 'vertical' ? 'col-lg-4' : ''
                } ${
                  isHomeUcc
                    ? noveltie.destacado
                      ? 'hightlighted'
                      : ''
                    : noveltie.novelty.destacadoHome
                      ? "" // Esta propiedad no esta funcionando 'hightlighted'
                      : ''
                }`}
                key={`noveltiesNodesId-${noveltie.novelty.id}`}
              >
                <div
                  className={`h-100 d-flex rounded shadow-sm news-card overflow-hidden position-relative
                    ${showView === 'horizontal' ? '' : 'flex-column'}`}
                >
                  <div
                    className={`news-image ${
                      showView === 'horizontal' ? 'horizontal' : ''
                    }`}
                    style={{
                      backgroundImage: `url(${
                        noveltie.novelty?.thumbnail
                          ? noveltie.novelty?.thumbnail.url
                          : null
                      })`
                    }}
                    role="img"
                    alt={noveltie.novelty?.thumbnailName || ''}
                  ></div>
                  <div className="flex-grow-1 p-4 d-flex flex-column news-info">
                    <h6>
                      <small>
                        {moment(noveltie.novelty?.date)
                          .locale('es')
                          .format('DD-MM-YYYY')}
                      </small>
                    </h6>

                    {/* Etiqueta de la noticia - novelties categories */}
                    {/* <div className="bg-sec px-2 py-1 text-white mb-2 fit-content">                            
                      {noveltiesCategory.nodes.map((category) => { 
                        return (noveltie.novelty.novelties_categories.map((noveltyCategory, i) => {
                          if (noveltyCategory === category.strapiId) {
                            if ((noveltie.novelty.novelties_categories.length - 1) > i) {
                              return category.name + " - "
                            } else {
                              return category.name
                            }
                          }
                        }))
                      })}
                    </div> */}
                    <h5 className="font-weight-bold">
                      {noveltie.novelty?.title}
                    </h5>
                    {showView === 'vertical' ? (
                      <div className="noticies-markdown">
                        <p>
                          {noveltie.novelty?.summary
                            ? noveltie.novelty.summary
                            : null}
                        </p>
                      </div>
                    ) : null}

                    <Link
                      to={`/noticias/${noveltie.novelty.slug}`}
                      className={`btn btn-sm btn-outline-info mt-auto fit-content text-uppercase ${
                        showView === 'horizontal' ? 'align-self-end' : ''
                      } ${
                        isHomeUcc
                          ? 'rounded-pill px-4 font-secondary eventuality-button-font-size'
                          : ''
                      }`}
                    >
                      VER MÁS
                    </Link>
                  </div>
                </div>
              </div>
            )
          }
          else if (isHomeUcc) {
            if (
              noveltie?.novelty.slug &&
              (noveltie?.novelty.dateTo === null ||
                moment(noveltie.novelty.dateTo, 'YYYY/MM/DD').isSameOrAfter(moment())
              )
            )
              return (
                <div
                  className={`mb-4 col-12 col-md-6 ${
                    showView === 'vertical' ? 'col-lg-4' : ''
                  } ${
                    isHomeUcc
                      ? noveltie.destacado
                        ? 'hightlighted'
                        : ''
                      : noveltie.destacadoHome
                        ? 'hightlighted'
                        : ''
                  }`}
                  key={`noveltiesNodesId-${noveltie.novelty.id}`}
                >
                  <div
                    className={`h-100 d-flex rounded shadow-sm news-card overflow-hidden position-relative
                      ${showView === 'horizontal' ? '' : 'flex-column'}`}
                  >
                    <div
                      className={`news-image 
                        ${showView === 'horizontal' ? 'horizontal' : ''}
                      `}
                      style={
                        noveltie.novelty?.thumbnail
                          ? {
                              backgroundImage: `url(${
                                isHomeUcc
                                  ? noveltie.novelty?.thumbnail.url
                                  : null
                              })`
                            }
                          : {
                              backgroundImage: `url(${noveltiesDefault.nodes[0].ImageNoveltiesDefault?.image.url})`
                            }
                      }
                      role="img"
                      alt={noveltie.novelty?.thumbnailName || ''}
                    ></div>
                    <div className="flex-grow-1 p-4 d-flex flex-column news-info">
                      <h6>
                        <small>
                          {moment(noveltie.novelty?.date)
                            .locale('es')
                            .format('DD-MM-YYYY')}
                        </small>
                      </h6>
                      
                      {/* Etiqueta de la noticia - novelties categories */}
                      {/* <div className="bg-sec px-2 py-1 text-white mb-2 fit-content">                            
                        {noveltiesCategory.nodes.map((category) => { 
                          return (noveltie.novelty.novelties_categories.map((noveltyCategory, i) => {
                            if (noveltyCategory === category.strapiId) {
                              if ((noveltie.novelty.novelties_categories.length - 1) > i) {
                                return category.name + " - "
                              } else {
                                return category.name
                              }
                            }
                          }))
                        })}
                      </div> */}

                      <h5 className="font-weight-bold">
                        {noveltie.title || noveltie.novelty?.title}
                      </h5>
                      {showView === 'vertical' ? (
                        <div className="noticies-markdown">
                          <p>
                            {noveltie.novelty?.summary
                              ? noveltie.novelty.summary
                              : null}
                          </p>
                        </div>
                      ) : null}

                      <Link
                        to={`/noticias/${
                          noveltie.slug || noveltie.novelty.slug
                        }`}
                        className={`btn btn-outline-info mt-auto fit-content text-uppercase ${
                          showView === 'horizontal' ? 'align-self-end' : ''
                        }  ${
                          isHomeUcc
                            ? 'rounded-pill px-4 font-secondary eventuality-button-font-size'
                            : ''
                        }`}
                      >
                        VER MÁS
                      </Link>
                    </div>
                  </div>
                </div>
              )
          }
        })}
      </div>
    </div>
  ) : null
}

// Construyendo estructura de las Noticias
const noveltyConstructor = (destacado = false, novelty) => {
  return ({
    id: novelty.id,
    destacado: destacado,
    novelty: {
      id: novelty.id,
      slug: novelty.slug,
      title: novelty.title,
      destacadoHome: novelty.destacadoHome,
      thumbnailName: novelty.thumbnailName,
      thumbnail: { url: novelty.thumbnail?.url },
      date: novelty.date,
      dateTo: novelty.dateTo,
      novelties_categories: novelty.novelties_categories?.map((categories) => categories.id),
      summary: novelty.summary
    }
  })
}

export default NoticesAndNovelties

NoticesAndNovelties.propTypes = {
  novelties: PropTypes.array.isRequired,
  isHomeUcc: PropTypes.bool,
  title: PropTypes.string,
  latest: PropTypes.bool,
  showView: PropTypes.string
}
